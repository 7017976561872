import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import VueMoment  from "vue-moment";
import DateCalc from '@/plugin/DateCalc.js'
import device from 'vue-device-detector'


axios.defaults.baseURL = 'https://allevent.kr:28600';
// axios.defaults.baseURL = 'http://localhost:28600';

Vue.config.productionTip = false;

Vue.use(VueMoment);
Vue.use(DateCalc);
Vue.use(device);
// Vue.use(VueCarousel);

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
