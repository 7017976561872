<template>
  <div class="AllSection">

    <!-- <div class="ExpDateList">
      마감 임박
      <div class="ExpDateTime">
        {{nowTime}}
      </div>
    </div> -->

    <!------------------------------------------------------------------------------------------------------------
      맨 위 슬라이드
     ------------------------------------------------------------------------------------------------------------>
    <div class="TopSection">
     <vue-carousel :data="slideImgListObj" indicators="hover" :interval="3000"></vue-carousel>
    </div>

    <!------------------------------------------------------------------------------------------------------------
      이미지/목록으로 보기
     ------------------------------------------------------------------------------------------------------------>
    <div class="ListSelect">
      <div class="listViewSelect">
        <input type="radio" id="00" v-model="$store.state.listviewTp" value="00" checked><label for="00" @click="SelectList('00')">이미지로 보기</label>
        <input type="radio" id="01" v-model="$store.state.listviewTp" value="01"        ><label for="01" @click="SelectList('01')">목록으로 보기</label>
      </div>
    </div>

    <!------------------------------------------------------------------------------------------------------------
      추천 이벤트 목록
     ------------------------------------------------------------------------------------------------------------>
     <!-- <div class="center" v-for="(resultGet, index) in $store.state.recommandEventListObj" :key="index"> -->
      <div class="center">
        <div class="RecommandAllView">
          <div class="Header">
            <div class="LeftBar">
              <span @click="MoveDetail('추천')">
                <img src="@/assets/T_추천 이벤트.png" alt="">
              </span>
            </div>
            <div class="MiddleBar">
            </div>
            <div class="RightBar">
              <span @click="MoveDetail('추천')">전체보기</span>
            </div>
          </div>
        </div>
        <div class="RecommandList">
          <div class="RecommandListSection"  v-if="$store.state.listviewTp == '00'">
            <div class="Box" 
              v-for="(getList, listIndex) in $store.state.recommandEventListObj" 
              :key="listIndex"
            >
              <div class="item">
                <div class="upjong">
                  <div class="upjongLeft">
                    {{ getList.topKind }} / {{ getList.middleKind }}
                  </div>
                  <div class="upjongRightRed"   v-if="getList.newDate <= 2">New</div>
                  <div class="upjongRightRed"   v-else-if="getList.remainDay <=  3">마감임박</div>
                </div>
                <img :src="'http://allevent.kr/banner/' + getList.eventImage" @click="moveUrl(getList.eventId, getList.eventLink)">
                <div class="title" @click="moveUrl(getList.eventId, getList.eventLink)">
                  <div>{{getList.eventNm}}</div>
                </div>
                <div class="price">~ {{ getList.eventExpDtDash }}</div>
                <div class="body">
                  {{getList.eventDesc}}
                </div>
              </div>
            </div>
          </div>
          <div class="RecommandListSection" v-else>
            <div class="List"
                v-for="(getList, listIndex) in $store.state.recommandEventListObj" 
                :key="listIndex"
                v-if="listIndex < 10"
              >
                <span @click="moveUrl(getList.eventId, getList.eventLink)"><img :src="'http://allevent.kr/banner/' + getList.eventImage"></span>
                <span @click="moveUrl(getList.eventId, getList.eventLink)">{{getList.eventNm}}</span>
                <span>{{ getList.topKind }} / {{ getList.middleKind }}</span>
                <span>~ {{ getList.eventExpDtDash }}</span>
                <span>{{getList.eventDesc}}</span>
            </div>
        </div>
      </div>
    </div>

    <!------------------------------------------------------------------------------------------------------------
      추천 외 이벤트 목록
     ------------------------------------------------------------------------------------------------------------>
    <div class="center" v-for="(resultGet, index1) in $store.state.eventListObj" :key="index1">
      <div>

        <!-- <div class="center" v-if="index1 == 2">
          <img :src="'http://allevent.kr/banner/sd1.png'">
        </div>

        <div class="center" v-if="index1 == 4">
          <img :src="'http://allevent.kr/banner/sd2.png'">
        </div> -->
        
        <div class="RecommandAllView" 
          v-if="$store.state.eventListObj[index1].length > 0"
        >
          <div class="Header">
            <div class="LeftBar">
              <span @click="MoveDetail($store.state.eventListObj[index1][0].topKind)">
                <img src="@/assets/T_추천 이벤트.png"     alt="" v-if     ="$store.state.eventListObj[index1][0].topKind == '추천'">
                <img src="@/assets/T_전기전자-이벤트.png" alt="" v-else-if="$store.state.eventListObj[index1][0].topKind == '가전'">
                <img src="@/assets/T_공공공익-이벤트.png" alt="" v-else-if="$store.state.eventListObj[index1][0].topKind == '공공'">
                <img src="@/assets/T_문화예술-이벤트.png" alt="" v-else-if="$store.state.eventListObj[index1][0].topKind == '문화'">
                <img src="@/assets/T_일상생활-이벤트.png" alt="" v-else-if="$store.state.eventListObj[index1][0].topKind == '생활'">
                <img src="@/assets/T_식품음식-이벤트.png" alt="" v-else-if="$store.state.eventListObj[index1][0].topKind == '식품'">
                <img src="@/assets/T_패션뷰티-이벤트.png" alt="" v-else-if="$store.state.eventListObj[index1][0].topKind == '패션'">
                <img src="@/assets/T_종료-이벤트.png"     alt="" v-else-if="$store.state.eventListObj[index1][0].topKind == '종료'">
                <img src="@/assets/T_추천 이벤트.png"     alt="" v-else>
              </span>
            </div>
            <div class="MiddleBar">
            </div>
            <div class="RightBar">
              <span @click="MoveDetail($store.state.eventListObj[index1][0].topKind)">전체보기</span>
            </div>
          </div>
        </div>
        <div class="RecommandList">
          <div class="RecommandListSection"  v-if="$store.state.listviewTp == '00'">
            <div class="Box" 
              v-for="(getList, listIndex) in resultGet" 
              :key="listIndex"
              v-if="$store.state.eventListObj[index1].length > 0"
            >
              <div class="item">
                <div class="upjong">
                  <div class="upjongLeft">
                    {{ getList.topKind }} / {{ getList.middleKind }}
                  </div>
                  <div class="upjongRightRed"   v-if="getList.newDate <= 2">New</div>
                  <div class="upjongRightRed"   v-else-if="getList.remainDay <=  3">마감임박</div>
                </div>
                <img :src="'http://allevent.kr/banner/' + getList.eventImage" @click="moveUrl(getList.eventId, getList.eventLink)">
                <div class="title" @click="moveUrl(getList.eventId, getList.eventLink)">
                  <div>{{getList.eventNm}}</div>
                </div>
                <div class="price">~ {{ getList.eventExpDtDash }}</div>
                <div class="body">
                  {{getList.eventDesc}}
                </div>
              </div>
            </div>
          </div>
          <div class="RecommandListSection" v-else>
            <div class="List"
                v-for="(getList, listIndex) in resultGet" 
                :key="listIndex"
                v-if="$store.state.eventListObj[index1].length > 0"
              >
                <span @click="moveUrl(getList.eventId, getList.eventLink)"><img :src="'http://allevent.kr/banner/' + getList.eventImage"></span>
                <span @click="moveUrl(getList.eventId, getList.eventLink)">{{getList.eventNm}}</span>
                <span>{{ getList.topKind }} / {{ getList.middleKind }}</span>
                <span>~ {{ getList.eventExpDtDash }}</span>
                <span>{{getList.eventDesc}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>








    <!------------------------------------------------------------------------------------------------------------
      기간이 종료된 이벤트를 조회한다.
     ------------------------------------------------------------------------------------------------------------>
     <!-- <div class="center" v-for="(expResultGet, index2) in $store.state.finishEventListObj" :key="index2"> -->
      <div>
        <div class="RecommandAllView">
          <div class="Header">
            <div class="LeftBar">
              <span @click="MoveDetail('종료이벤트')">
                <img src="@/assets/T_종료-이벤트.png"     alt="" class="bigs">
              </span>
            </div>
            <div class="MiddleBar">
            </div>
            <div class="RightBar">
              <span @click="MoveDetail('종료이벤트')">전체보기</span>
            </div>
          </div>
        </div>

        <div class="RecommandList">
          <!-- <div class="RecommandListSection"  v-if="$store.state.listviewTp == '00'">
            <div class="Box" 
              v-for="(getList, listIndex) in $store.state.finishEventListObj" 
              :key="listIndex"
              v-if="$store.state.finishEventListObj.length > 0"
            >
              <div class="item">
                <div class="upjong">
                  <div class="upjongLeft">
                    {{ getList.topKind }} / {{ getList.middleKind }}
                  </div>
                  <div class="upjongRightRed"   v-if="getList.newDate <= 2">New</div>
                  <div class="upjongRightRed"   v-else-if="getList.remainDay <=  3">종료됨</div>
                </div>
                <img :src="'http://allevent.kr/banner/' + getList.eventImage" @click="moveUrl(getList.eventId, getList.eventLink)">
                <div class="title" @click="moveUrl(getList.eventLink)">
                  <div>{{getList.eventNm}}</div>
                </div>
                <div class="price">~ {{ getList.eventExpDtDash }}</div>
                <div class="body">
                  {{getList.eventDesc}}
                </div>
              </div>
            </div>
          </div> -->
          <div class="RecommandListSection">
            <div class="List"
                v-for="(getList, listIndex) in $store.state.finishEventListObj" 
                :key="listIndex"
                v-if="$store.state.finishEventListObj.length > 0"
              >
                <span @click="moveUrl(getList.eventId, getList.eventLink)"><img :src="'http://allevent.kr/banner/' + getList.eventImage"></span>
                <span @click="moveUrl(getList.eventId, getList.eventLink)">{{getList.eventNm}}</span>
                <span>{{ getList.topKind }} / {{ getList.middleKind }}</span>
                <span>~ {{ getList.eventExpDtDash }}</span>
                <span>{{getList.eventDesc}}</span>
            </div>
          </div>
        </div>
      </div>
    <!-- </div> -->











    <!------------------------------------------------------------------------------------------------------------
      추천 외 이벤트 목록
     ------------------------------------------------------------------------------------------------------------>
    <!-- <div class="center" v-for="(resultGet, index) in $store.state.eventListObj" :key="index">
      <div v-if="index > 1 && index != 3">

        <div class="center" v-if="index == 5">
          <img :src="'http://allevent.kr/banner/sd1.png'">
        </div>

        <div class="center" v-if="index == 6">
          <img :src="'http://allevent.kr/banner/sd2.png'">
        </div>
        
        <div class="RecommandAllView">
          <div class="Header">
            <div class="LeftBar">
              <span @click="MoveDetail($store.state.eventListObj[0][index-2].topKind)">
                <img src="@/assets/T_추천 이벤트.png"     alt="" v-if     ="$store.state.eventListObj[0][index-2].topKind == '추천'">
                <img src="@/assets/T_전기전자-이벤트.png" alt="" v-else-if="$store.state.eventListObj[0][index-2].topKind == '가전'">
                <img src="@/assets/T_공공공익-이벤트.png" alt="" v-else-if="$store.state.eventListObj[0][index-2].topKind == '공공'">
                <img src="@/assets/T_문화예술-이벤트.png" alt="" v-else-if="$store.state.eventListObj[0][index-2].topKind == '문화'">
                <img src="@/assets/T_일상생활-이벤트.png" alt="" v-else-if="$store.state.eventListObj[0][index-2].topKind == '생활'">
                <img src="@/assets/T_식품음식-이벤트.png" alt="" v-else-if="$store.state.eventListObj[0][index-2].topKind == '식품'">
                <img src="@/assets/T_패션뷰티-이벤트.png" alt="" v-else-if="$store.state.eventListObj[0][index-2].topKind == '패션'">
                <img src="@/assets/T_추천 이벤트.png"     alt="" v-else>
              </span>
            </div>
            <div class="MiddleBar">
            </div>
            <div class="RightBar">
              <span @click="MoveDetail($store.state.eventListObj[0][index-2].topKind)">전체보기</span>
            </div>
          </div>
        </div>
        <div class="RecommandList">
          <div class="RecommandListSection"  v-if="$store.state.listviewTp == '00'">
            <div class="Box" 
              v-for="(getList, listIndex) in resultGet" 
              :key="listIndex"
            >
              <div class="item">
                <div class="upjong">
                  <div class="upjongLeft">
                    {{ getList.topKind }} / {{ getList.middleKind }}
                  </div>
                  <div class="upjongRightRed"   v-if="getList.remainDay <=  3">마감임박</div>
                </div>
                <img :src="'http://allevent.kr/banner/' + getList.eventImage" @click="moveUrl(getList.eventId, getList.eventLink)">
                <div class="title" @click="moveUrl(getList.eventLink)">
                  <div>{{getList.eventNm}}</div>
                </div>
                <div class="price">~ {{ getList.eventExpDtDash }}</div>
                <div class="body">
                  {{getList.eventDesc}}
                </div>
              </div>
            </div>
          </div>
          <div class="RecommandListSection" v-else>
            <div class="List"
                v-for="(getList, listIndex) in resultGet" 
                :key="listIndex"
                v-if="listIndex < 10"
              >
                <span @click="moveUrl(getList.eventId, getList.eventLink)"><img :src="'http://allevent.kr/banner/' + getList.eventImage"></span>
                <span @click="moveUrl(getList.eventId, getList.eventLink)">{{getList.eventNm}}</span>
                <span>{{ getList.topKind }} / {{ getList.middleKind }}</span>
                <span>~ {{ getList.eventExpDtDash }}</span>
                <span>{{getList.eventDesc}}</span>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
  
<script>
import axios       from "axios";
import moment      from "moment";
import VueCarousel from '@chenfengyuan/vue-carousel';

export default {
  name: 'campaign',
  components: {
      moment
    , VueCarousel
  },
  data() {
    return {
        slideImgListObj : []
      , stringLen       : []
      , index4          : 0
      , resultObj       : ''
      , searchTime      : ''
      , nowTime         : ''
    }
  },
  methods: {
    //******************************************************************************
    // 상세페이지 이동
    //******************************************************************************
    moveUrl(eventId, url) {
      axios.get("/CreateEventClick",
      {
        params: {
          eventId         : eventId
        , remoteAddr      : document.remoteAddr
        , httpHost        : document.httpHost
        , httpUserAgent   : document.httpUserAgent
        , httpReferer     : document.referrer
        , requestUri      : document.requestUri
        }  
      })
      window.open(url);
    },
    //******************************************************************************
    // 슬라이드 배너
    //******************************************************************************
    SlideImageList() {
      axios.get("/SlideImageList", {
        params: {
          dummy: 'dummy'
        }
      })
      .then(response => {
        this.stringLen = response.data;
        for(let i = 0 ; i < this.stringLen.length ; i++) {
          let scrapString = '<div class="example-slide"><a href="' + this.stringLen[i].path + '" target="_blank"><img src="http://allevent.kr/banner/' + this.stringLen[i].imageNm + '"></a></div>';
          this.slideImgListObj.push(scrapString);
        }

        this.RecommandList();
      })
      .catch(error => {
        console.log(error);
      })
    },
    //******************************************************************************
    // 추천, 업종별 이벤트 조회
    //   - 최초조회 또는 상단 몽땅 이미지 조회시 처리
    //******************************************************************************
    RecommandList() {
      if(this.$store.state.SearchWord == null)
        this.$store.state.SearchWord = '';

      axios.get("/RecommandList",
      {
        params: {
          searchWord: this.$store.state.SearchWord
        }  
      })
      .then(response => {
        console.log(response);
        this.$store.state.recommandEventListObj = response.data;
        this.NonRecommandList();
      })
      .catch(error => {
        console.log(error);
      })
    },
    //******************************************************************************
    // 추천, 업종별 이벤트 조회
    //   - 최초조회 또는 상단 몽땅 이미지 조회시 처리
    //******************************************************************************
    NonRecommandList() {
      if(this.$store.state.SearchWord == null)
        this.$store.state.SearchWord = '';

      this.$store.state.eventListObj = [];
      
      axios.get("/NonRecommandList",
      {
        params: {
            topKind: this.$store.state.topKind
          , searchWord: this.$store.state.SearchWord
        }
      })
      .then(response => {
        this.$store.state.eventListObj = response.data;
        this.ExpireRecommandList();
      })
      .catch(error => {
        console.log(error);
      })
    },
    //******************************************************************************
    // 추천, 업종별 이벤트 조회
    //   - 최초조회 또는 상단 몽땅 이미지 조회시 처리
    //******************************************************************************
    ExpireRecommandList() {
      this.$store.state.finishEventListObj = [];
      
      axios.get("/ExpireRecommandList",
      {
        params: {
          dummy: 'dummy'
        }
      })
      .then(response => {
        this.$store.state.finishEventListObj = response.data;
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      })
    },
    //******************************************************************************
    // 셀렉트박스에 사용되는 업종목록을 조회한다.
    //******************************************************************************

    //******************************************************************************
    // 추천 이벤트를 조회한다.
    //******************************************************************************

    //******************************************************************************
    // 업종별 이벤트를 조회한다.
    //******************************************************************************












    //******************************************************************************
    // 이벤트 리스트
    //******************************************************************************
    EventList() {
      if(this.$store.state.SearchWord == null)
        this.$store.state.SearchWord = '';

      axios.get("/EventArrayList",
      {
        params: {
          searchWord: this.$store.state.SearchWord
        }  
      })
      .then(response => {
        this.$store.state.eventListObj = response.data;
      })
      .catch(error => {
        console.log(error);
      })
    },
    //******************************************************************************
    // 추천이벤트로 이동
    //******************************************************************************
    MoveDetail(topKind) {
      // if(topKind == '전체')
      //   topKind = '추천';

      this.$store.state.topKind = topKind;
      this.$router.push({ name: 'detail', params: { topKind: topKind, searchWord: this.$store.state.SearchWord }})
    },
    //******************************************************************************
    // 리스트 선택
    //******************************************************************************
    SelectList(tp) {
      (tp == '00') ? this.$store.state.listviewTp = '00' : this.$store.state.listviewTp = '01';
    },
  },
  created() {
    window.scrollTo(0,0);

    console.log(this.$store.state.topKind);

    if(this.$store.state.topKind == '' || this.$store.state.topKind == 'undefined' || this.$store.state.topKind == null) {
      this.$store.state.topKind = '';
      this.$store.state.SearchWord = '';
      console.log("Stop1");
      this.SlideImageList();
    }
    else {
      this.$store.state.topKind = this.$route.query.topKind;
      this.$store.state.SearchWord = '';
      console.log("Stop2");
      this.MoveDetail(this.$store.state.topKind);
    }    
  },
  props: {
    searchWord: {
      type: String,
      default: ''
    }
  }
}
</script>
  
<style scoped>
  /*------------------------------------------------------------------------------------------------------------
    맨 위 슬라이드
  ------------------------------------------------------------------------------------------------------------*/
  .AllSection {
    margin-bottom: 20px;
  }
  .TopSection {
    margin: 0 auto;
    padding-top: 140px;
    z-index: 999;
  }
  .TopSection .Slide {
    margin: 0 auto;
  }

  /*------------------------------------------------------------------------------------------------------------
    이미지/목록으로 보기
  ------------------------------------------------------------------------------------------------------------*/
  .ListSelect {
    margin: 0 auto;
    padding: 20px 0px;
  }
  .ListSelect .listViewSelect {
    margin: 0 auto;
    width: 1200px;
    text-align: right;
  }
  .ListSelect .listViewSelect input[type="radio"] {
    display: none;
  }
  .ListSelect .listViewSelect input[type="radio"] + label {
    cursor: pointer;  
    border: 1px solid rgb(255,115,115);
    font-size: 13px;
    font-family: 'Noto Sans KR', sans-serif;
    padding: 5px 10px;
    color: rgb(255,115,115);
    border-radius: 100px;
    margin-left: 14px;
    text-overflow: ellipsis;
  }
  .ListSelect .listViewSelect input[type="radio"]:checked + label {
    background: rgb(255,115,115);
    color: #fff;
  }

  /*------------------------------------------------------------------------------------------------------------
    이벤트
  ------------------------------------------------------------------------------------------------------------*/
  .RecommandAllView {
    margin: 0 auto;
    color: black;
    background: rgb(255,255,255);
    border-bottom: 1px solid rgb(236,16,16);
    height: 50px;
    position: sticky;
    top: 80px;
    left: 0 px;
    z-index: 100;
    opacity: 80%;
  }
  .RecommandAllView .Header {
    margin: 0 auto;
    width: 1200px;
    height: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
  }
  .RecommandAllView .Header .LeftBar {
    width: 20px;
    height: 20px;
    cursor: pointer;  
  }
  .RecommandAllView .Header .LeftBar img {
    /* width: 20px; */
    height: 25px;
  }

  .RecommandAllView .Header .LeftBar img .bigs{
    /* width: 20px; */
    height: 40px;
  }
  .RecommandAllView .Header .LeftBar span {
    display: inline-block;
    position: relative;
    font-size: 25px;
    top: -7px;
    width: 130px;
    font-family: 'SCDream5';
  }
  .RecommandAllView .Header .LeftBar span:hover {
    text-decoration: underline;
    text-underline-offset:2px;
    text-decoration-thickness: 2px;
  }
  .RecommandAllView .Header .MiddleBar {
    text-align: left;
  }
  .RecommandAllView .Header .MiddleBar span {
    font-family: 'SCDream5';
    text-align: left;
    left: -250px;
  }
  .RecommandAllView .Header .RightBar span {
    font-size: 15px;
    /* right: -5px; */
    width: 80px;
    font-family: 'SCDream5';
    /* padding: 0px 15px; */
    text-align: right;
    cursor: pointer;
  }
  .RecommandAllView .Header .RightBar span:hover {
    text-decoration: underline;
    text-underline-offset:2px;
    text-decoration-thickness: 2px;
  }
  .RecommandList {
    /* background-color: rgb(252, 233, 226); */
  }
  .RecommandList .RecommandListSection {
    margin: 20px auto;
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 30px;
  }
  .RecommandList .RecommandListSection .Box {
    width: 220px;
    display: block;
    padding: 15px 10px;
    /* margin-bottom: 20px; */
  }
  .RecommandList .RecommandListSection .Box .item {
    margin: 0 auto;
    width: 210px;
    overflow: hidden;
    margin-top: 10px;
  }
  .RecommandList .RecommandListSection .Box .item .upjong {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .RecommandList .RecommandListSection .Box .item .upjong .upjongLeft {
    background-color: white;
    border: 1px solid rgb(211,211,211);
    border-radius: 30px;
    padding: 0px 10px 5px 10px;
    height: 25px;
    line-height: 30px;
    font-size: 12px;
    z-index: 5;
  }
  .RecommandList .RecommandListSection .Box .item .upjong .upjongRightRed {
    color: rgb(243,86,35);
    border-radius: 5px;
    padding: 2px 5px 2px 5px;

    height: 20px;
    line-height: 20px;
    font-size: 13px;
    font-weight: 600;
    z-index: 5;
  }
  @keyframes blink-effect {
    0% {
      background-color: #fff;
    }
    25% {
      background-color: #2a9e2a;
    }
    50% {
      background-color: #fff;
    }
    75% {
      background-color: #2a9e2a;
    }
    100% {
      background-color: #fff;
    }
  }
  .RecommandList .RecommandListSection .Box .item .upjong .upjongRightGreen {
    /* color: rgb(255,255,255); */
    /* background-color: #2a9e2a; */

    color: #2a9e2a;
    border-radius: 5px;
    padding: 2px 5px 2px 5px;

    height: 20px;
    line-height: 20px;
    font-size: 13px;
    font-weight: 700;
    z-index: 5;
    /* animation: blink-effect 3s infinite; */
  }
  .RecommandList .RecommandListSection .Box .item .upjong .upjongRightNormal {
    color: black;
    background-color: rgb(240,240,240);
    border: 1px solid rgb(240,240,240);
    border-radius: 5px;
    padding: 0px 10px 5px 10px;
    height: 25px;
    line-height: 30px;
    font-size: 13px;
    font-weight: 600;
    z-index: 5;

    position: relative;
    top: 50px;
    right: 10px;
  }
  .RecommandList .RecommandListSection .Box .item img {
    width: 210px;
    height: 190px;
    border-radius: 15px;
    transition: all 0.1s linear;
    cursor: pointer;
    box-shadow         : 5px 5px 10px rgba(0,0,0,0.1), -5px -5px 10px rgba(255,255,255,1);
    -webkit-box-shadow : 5px 5px 10px rgba(0,0,0,0.1), -5px -5px 10px rgba(255,255,255,1);
    -moz-box-shadow    : 5px 5px 10px rgba(0,0,0,0.1), -5px -5px 10px rgba(255,255,255,1);
    overflow: hidden;
  }
  .RecommandList .RecommandListSection .Box .item img:hover {
    transform: scale(1.01);
    /* box-shadow:-2px -2px -2px -2px #999;
　　-moz-box-shadow: 3px 3px 3px 3px #999;
　　-webkit-box-shadow: 3px 3px 3px 3px #999;
　　box-shadow: 3px 3px 3px 3px #999; */
  }
  .RecommandList .RecommandListSection .Box .item .title {
    padding-top: 10px;
    padding-left: 10px;
    text-align: left;
    font-family: 'SCDream5';
    font-size: 12px;
    height: 30px;
    color:rgb(34,94,106);
    cursor: pointer;
  }
  .RecommandList .RecommandListSection .Box .item .title:hover {
    text-decoration: underline;
    text-underline-offset:2px;
  }
  .RecommandList .RecommandListSection .Box .item .price {
    padding: 10px 15px 10px 15px;
    text-align: right;
    font-family: 'SCDream5';
    font-size: 13px;
    font-weight: bold;
  }
  .RecommandList .RecommandListSection .Box .item .body {
    display: inline-block;
    /* margin-top: 10px; */
    /* border-radius: 10px; */
    margin-left: 3px;;
    width: 205px;
    height: 62px;
    line-height: 150%;
    text-align: left;
    font-size: 13px;
    font-family: 'Noto Sans KR', sans-serif;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  /*-----------------------------------------------------
    가로로 표시
  -----------------------------------------------------*/
  .RecommandList .RecommandListSection .List {
    width: 1200px;
    display: inline-block;
    padding: 5px 10px;
    text-align: left;
  }
  .RecommandList .RecommandListSection .List .item {
    margin: 0 auto;
    overflow: hidden;
  }
  .RecommandList .RecommandListSection .List img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }
  .RecommandList .RecommandListSection .List span:nth-child(1) {
    display: inline-block;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    cursor: pointer;
  }
  .RecommandList .RecommandListSection .List span:nth-child(2) {
    display: inline-block;
    width: 300px;
    margin-left: 15px;
    vertical-align: top;
    font-size: 15px;
    cursor: pointer;
  }
  .RecommandList .RecommandListSection .List span:nth-child(2):hover {
    text-decoration: underline;
    text-underline-offset:2px;
    text-decoration-thickness: 1px;
  }
  .RecommandList .RecommandListSection .List span:nth-child(3) {
    display: inline-block;
    width: 100px;
    margin-left: 15px;
    vertical-align: top;
    font-size: 13px;
    border-radius: 30px;
    background-color: rgb(237,244,247);
    color: rgb(58,132,179);
    text-align: center;
    padding: 3px 0px;
  }
  .RecommandList .RecommandListSection .List span:nth-child(4) {
    display: inline-block;
    width: 90px;
    margin-left: 15px;
    vertical-align: top;
    font-size: 13px;
  }
  .RecommandList .RecommandListSection .List span:nth-child(5) {
    display: inline-block;
    width: 580px;
    margin-left: 15px;
    vertical-align: top;
    font-family: sans-serif;
    font-size: 12px;
  }
  .ExpDateList {
    margin: 0 auto;
    font-size: 32px;
    font-family: 'SCDream7';
  }
  .ExpDateList .ExpDateTime {
    margin: 0 auto;
    margin-top: 60px;
    width: 300px;
    height: 50px;
    vertical-align: top;
    font-size: 25px;
    border-radius: 3px;
    background-color: rgb(173,147,119);
    color: white;
    text-align: center;
  }

  .center {
    text-align: center;
  }
</style>