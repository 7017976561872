import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      selectList: '00'
    , searchWord: 'LG' // 검색어
    , eventListObj : ''   // EventList
    , recommandEventListObj : ''   // EventList
    , finishEventListObj : ''
    , finishEventUpjongListObj : ''
    , listviewTp   : '00'
  },
  getters: {},
  mutations: {
    ListCommit(state, payload) {
      state.eventListObj = payload;
    }
  },
  actions: {},
  modules: {},
})
