<template>
  <div id="app">
    <nav>
      <div class="fullSection">
        <div class="headerSection">
          <div class="leftHeader" @click="Home()">
            <img src="@/assets/left.png" alt="">
          </div>
          <div class="middleHeader">
            <div class="findEdit">
              <input 
              type="text"
              title="검색어 입력"
              maxlength="100"
              style="ime-mode:active;"
              autocomplete="off"
              placeholder="검색어를 입력해 주세요."
              :value="$store.state.SearchWord"
              @input="ChangeWord"
              v-on:keyup.enter="SearchWord()"
              >
              <i class="fa-solid fa-magnifying-glass" @click="SearchWord($store.state.SearchWord)"></i>
            </div>
          </div>
          <div class="rightHeader">
            <img src="@/assets/right.png" alt="">
          </div>
        </div>
      </div>
      <div class="MenuSection">
        
        <div class="leftMenu">
          <img src="@/assets/1_카테고리.png" alt="" >
          <img src="@/assets/2_추천.png"     alt="" @click="MoveDetail('추천')">
          <img src="@/assets/4_전기전자.png" alt="" @click="MoveDetail('가전')">
          <img src="@/assets/5_패션뷰티.png" alt="" @click="MoveDetail('패션')">
          <img src="@/assets/6_식품음식.png" alt="" @click="MoveDetail('식품')">
          <img src="@/assets/7_일상생활.png" alt="" @click="MoveDetail('생활')">
          <img src="@/assets/8_문화예술.png" alt="" @click="MoveDetail('문화')">
          <img src="@/assets/9_공공공익.png" alt="" @click="MoveDetail('공공')">
          <img src="@/assets/10_종료.png" alt="" @click="MoveDetail('종료이벤트')">
        </div>
      </div>
      <div class="bodySection">
        <div class="bodyArea">
          <router-view/>
        </div>
      </div>
    </nav>
    <div id="footer">
      <div class="footerTop">
        <div class="top">
          마케팅디자인<br>
          서울특별시 구로구 디지털로 26길 111, 제이앤케이디지털타워 708호<br>
          대표번호 : 1533-3757, 문의전화번호 : 010-3846-0333 (문자/카톡 상담가능)<br>
          사업자등록증번호: 567-87-00066&nbsp;&nbsp;&nbsp;통신판매 신고번호: 제2020-서울구로-2892호
        </div>
        <div class="bottom">
          <img src="http://allevent.kr/banner/Kibo.png">
          <img src="http://allevent.kr/banner/Venture.png">
          <img src="http://allevent.kr/banner/KB.png">
        </div>
      </div>
      <div class="footerBottom">
        <div class="top">
          COPYRIGHT© 2023 마케팅디자인. ALL RIGHTS RESERVED
        </div>
        <div class="bottom">
          <a href="javascript:void(0)">이용약관</a>
          <a href="javascript:void(0)">개인정보처리방침</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import axios from "axios";

export default {
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    //******************************************************************************
    // 홈으로 가기
    //******************************************************************************
    Home() {
      this.$store.state.SearchWord = '';
      this.$router.push({ name: 'bypassCampaign', params: { topKind: '', searchWord: '' }});
      window.scrollTo({top:location, behavior:'smooth'});
    },
    //******************************************************************************
    // 추천이벤트로 이동
    //******************************************************************************
    MoveDetail(topKind) {
      this.$store.state.topKind = topKind;
      this.$router.push({ name: 'bypassDetail'  , params: { topKind: this.$store.state.topKind, searchWord: this.$store.state.SearchWord }});
      window.scrollTo({top:location, behavior:'smooth'});
    },
    //******************************************************************************
    // 검색 후 처리
    //******************************************************************************
    SearchWord() {
      // if(this.$store.state.SearchWord == null)
      //   this.$store.state.SearchWord = '';

      let findResult = window.location.href.indexOf('campaign');

      // 메인화면에서 검색함.
      if( findResult>= 0) {
        this.$router.push({ name: 'bypassCampaign', params: { topKind: this.$store.state.topKind, searchWord: this.$store.state.SearchWord }});
      }
      // 업종항목에서 검색함.
      else {
        this.$router.push({ name: 'bypassDetail'  , params: { topKind: this.$store.state.topKind, searchWord: this.$store.state.SearchWord }});
      }
    },
    ChangeWord(e) {
      this.$store.state.SearchWord = e.target.value;
    },
  },
  created() {
    document.title = "ALL EVENT";
    window.scrollTo(0,0);

    if(this.$device.windows == true)
      document.title = "ALL EVENT";
    else {
      document.title = "Mobile ALL EVENT";
      //window.open("http://m.allevent.kr", "_self");
      window.location.href = "http://m.allevent.kr";
    }

    console.log(this.$route.query.topKind);

    if(this.$route.query.topKind == '' || this.$route.query.topKind == 'undefined' || this.$route.query.topKind == null) {
      this.$store.state.topKind = '';
      this.$store.state.SearchWord = '';
      console.log("Stop1");
      this.$router.push({ name : 'campaign', });
    }
    else {
      this.$store.state.topKind = this.$route.query.topKind;
      console.log("Stop2");
      this.$router.push({ name: 'bypassDetail'  , params: { topKind: this.$store.state.topKind, searchWord: this.$store.state.SearchWord }});
    }

    console.log(this.$store.state.topKind);
    
    
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap');

@font-face { font-family: "SCDream1"; src: url("../fonts/SCDream1.otf"); }
@font-face { font-family: "SCDream2"; src: url("../fonts/SCDream2.otf"); }
@font-face { font-family: "SCDream3"; src: url("../fonts/SCDream3.otf"); }
@font-face { font-family: "SCDream4"; src: url("../fonts/SCDream4.otf"); }
@font-face { font-family: "SCDream5"; src: url("../fonts/SCDream5.otf"); }
@font-face { font-family: "SCDream6"; src: url("../fonts/SCDream6.otf"); }
@font-face { font-family: "SCDream7"; src: url("../fonts/SCDream7.otf"); }
@font-face { font-family: "SCDream8"; src: url("../fonts/SCDream8.otf"); }
@font-face { font-family: "SCDream9"; src: url("../fonts/SCDream9.otf"); }

body {
  padding: 0px;
  margin: 0px;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family:'SCDream4';

  /* -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none; */
}
nav {
  padding: 0px;
  margin: 0px;
  background-color: #fff;
  
}
nav a {
  font-weight: bold;
  color: #9c9c9c;
  text-decoration-line: none;
  font-family: 'SCDream5';
  font-weight: normal;
}
nav a.router-link-exact-active {
  color: #1c221f;
  text-decoration-line: none;

  /* left: 0px; */
  /* width: 165px; */
  /* height: 5px; */
  /* margin-top: 5px; */
  /* background-color: #1c221f; */
}
























.fullSection {
  box-sizing: border-box;
  min-width: 100px;
  background-color: #fff;
  /* border-bottom: 1px solid rgb(143, 61, 61); */
  border-bottom: 1px solid #ccc;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 998;
}
.fullSection img {
  position: relative;
  top: 0px;
  right: 0px;
}



.MenuSection {
  margin: 0 auto;
  position: fixed;
  height: 60px;
  top: 95px;
  box-sizing: border-box;
  
  /* text-align: left; */
  
  border-bottom: 8px solid red;
  z-index: 999;
  background-color: #fff;
  left: 0px;
  width: 100vw;

  top: 80px;
}

.MenuSection .leftMenu {
  margin: 0 auto;
  width: 1210px;
  position: relative;
  top: 15px;
  left: 0px;
  box-sizing: border-box;
  
  text-align: center;
  cursor: pointer;
}

.MenuSection .leftMenu img {
  height: 25px;
}
.MenuSection .leftMenu img:nth-child(1) {
  margin-right: 10px;
  height: 20px;
}

.MenuSection .leftMenu img:nth-child(2),
.MenuSection .leftMenu img:nth-child(3),
.MenuSection .leftMenu img:nth-child(4),
.MenuSection .leftMenu img:nth-child(5),
.MenuSection .leftMenu img:nth-child(6),
.MenuSection .leftMenu img:nth-child(7),
.MenuSection .leftMenu img:nth-child(8),
.MenuSection .leftMenu img:nth-child(9),
.MenuSection .leftMenu img:nth-child(10) {
   margin-left: 40px;
}

.headerSection {
  margin: 0 auto;
  position: relative;
  height: 80px;
  top: 0px;
  box-sizing: border-box;
  margin-bottom: 0px;
  width: 1200px;
}
.headerSection .leftHeader {
  position: absolute;
  left: 0px;
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  text-align: left;
  font-size: 26px;
  font-weight: 700;
  padding: 22px 0px;
  cursor: pointer;
  font-family:'SCDream8';
  /* color: rgb(212, 42, 42); */
  color: rgb(100,100,100);
}
.headerSection .leftHeader img {
  /* position: relative; */
  /* width: 130px;
  top: -8px; */
  top: -12px;
  height: 50px;
}
.headerSection .middleHeader {
  left: 250px;
  position: absolute;
  display: flex;
  justify-content: space-around;  
  right: 0px;
  width: 600px;
  height: 100%;
  box-sizing: border-box;
  /* text-align: right; */
  font-size: 18px;
  font-weight: 700;
  align-items: center;
}
.headerSection .middleHeader .findEdit {
  width: 400px;
  height: 40px;
  border: 1px solid #aaa;
  border-radius: 40px;
  text-align: left;
  padding-left: 20px;
  color: #999;
}
.headerSection .middleHeader .findEdit input[type="text"] {
  width: 360px;
  height: 32px;
  border: none;
  outline: 0;
  font-weight: bold;
  color: #999;
  padding: 3px;
}
.headerSection .middleHeader .findEdit i {
  position: relative;
  top: 2px;
  left: 0px;
  cursor: pointer;
}
.headerSection .middleHeader .findEdit i:hover {
  color: #888;
}
.headerSection .rightHeader {
  display: inline-block;
  float: right;
  left: 900px;
  position: absolute;
  width: 298px;
  height: 100%;
  box-sizing: border-box;
  font-family: 'SCDream4';
  font-size: 12px;
  color: #777;
  /* padding: 32px 0px; */
  padding-top: 15px;
  text-align: right;
}
.headerSection .rightHeader .user {
  height: 30px;
  padding: 5px 8px 5px 50px;
  color: rgb(65, 65, 65);
  font-size: 12px;
  letter-spacing: -0.3px;
  position: relative;
  margin-top: -4px;
}
.headerSection .rightHeader .user02 {
  height: 30px;
  padding: 5px 10px;
  display: inline-block;
  color: #444;
  font-size: 12px;
  letter-spacing: -0.3px;
}
.headerSection .rightHeader .level{
  position: absolute;
  display: inline-block;
  top: -2px;
  left: 9px;
  width: 31px;
  height: 21px;
  /* background: url("@/assets/levelBtn.png"); */
  /* background-color: #2c3e50; */
  padding-top: 3px;
  padding-left: 2px;
  text-align: center;
  color: #fff;
  font-size: 10px;
}
.headerSection .rightHeader span {
  cursor: pointer;
}
.headerSection .rightHeader button {
  width: 80px;
  margin-left: 5px;
  height: 30px;
  border: none;
  background-color: rgb(14, 54, 129);
  color: #fff;
  font-weight: 700;
  border-radius: 5px;
  margin-top: -8px;
  cursor: pointer;
}
.headerSection .rightHeader img {
  height: 45px;
}










.bodySection {
  margin: 0 auto;
}
.bodySection .bodyArea {
  position: relative;
  margin: 0 auto;
  /* border: 1px solid #ccc; */
}
.bodySection .bodyArea .kakao {
  width: 1200px;
  position: fixed;
  width: 80px;
  height: 80px;
  margin-left: 550px;
  top: 150px;
  cursor: pointer;
}
.bodySection .bodyArea .kakao img {
  position: fixed;
  width: 80px;
  height: 80px;
  margin-left: 620px;
  top: 150px;
  cursor: pointer;
}
.footerSection {
  display: block;
  position: sticky;
  height: 100px;
  box-sizing: border-box;
  background-color: rgb(188, 203, 230);
}
#footer {
  border-top: 1px solid #e5e5e5;
  text-align: center;
}
#footer > div {
  margin: 0 auto;
  padding: 13px;
  text-align: left;
  width: 1200px;
}
#footer .footerTop {
  padding: 26px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}
#footer .footerTop .bottom img {
  margin-right: 15px;
  vertical-align: middle;
}
#footer .footerBottom .bottom {
  height: 13px;
  margin-top: -17px;
  margin-bottom: 20px;
}
#footer .footerBottom .bottom a {
  padding: 0 18px;
  float: right;
}
#footer .footerBottom .bottom a:last-child {
  position: relative;
}
#footer .footerBottom .bottom a:last-child::before {
  clear: both;
  content: "";
  width: 1px;
  height: 10px;
  left: -0.5px;
  top: 50%;
  background: #e0e0e0;
  position: absolute;
  transform: translateY(-50%);
}
#footer .top,
#footer .bottom,
#footer .bottom a {
  letter-spacing: -0.48px;
  color: #222;
  font-size: 12px;
}
.red {
  color: rgb(243,86,35);
}
</style>