import Vue from 'vue'
import VueRouter from 'vue-router'
import campaign  from "../views/campaign.vue";   // 상담사 집계

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '/',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/campaign.vue')
  },
  {
    path: '/campaign',
    name: 'campaign',
    // component: () => import(/* webpackChunkName: "campaign" */ '../views/campaign.vue')
    component: campaign, 
    props: (route) => ({ topKind: route.query.topKind })
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import(/* webpackChunkName: "detail" */ '../views/detail.vue')
  },
  {
    path: '/bypassCampaign',
    name: 'bypassCampaign',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/bypassCampaign.vue')
  },
  {
    path: '/bypassDetail',
    name: 'bypassDetail',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/bypassDetail.vue')
  },

  {
    path: '/m/campaign',
    name: 'mcampaign',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/mobile/campaign.vue')
  },













  {
    path: '/ZZZZZZZZZZZZZZZZZZZZ',
    name: 'ZZZZZZZZZZZZZZZZZZZZ',
    component: () => import(/* webpackChunkName: "detail" */ '../views/ZZZZZZZZZZZZZZZZZZZZ.vue')
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "campaign" */ '../views/campaign.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
